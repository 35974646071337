<template>
  <div v-if="intro.introImage" style="width:100%;height:100%;position:relative">
    <template v-if="intro.introImage.isImage">
      <div v-if="intro.introImage" class="introImageBackGroundV" :style="{'background-image':'url('+intro.introImage.url.replace('/0/0/0/0/', '/800/0/0/0/')+')'}">
      </div>
      <div class="introImageBackGroundBox">
        <div :class="getAnimationClass('introAnimation1')">
          <img :src="intro.introImage.url.replace('/0/0/0/0/', '/800/0/0/0/')" />
        </div>
      </div>
      <div v-if="intro.isPro&& intro.logo" class="logoBox">
        <div class="text-center" :class="getAnimationClass('introAnimation3')">
          <img style="max-width:100%" :src="intro.logo.url">
        </div>
      </div>
      <div class="introMainBox">
        <div class="text-shadow" :class="getAnimationClass('introAnimation2')" style="width: 500px; max-width: 100%;">
          <h3>{{intro.title}}</h3>
          <div v-if="intro.text">
            <div class="articleBox">
              <article class="introText" v-html="intro.text"></article>
            </div>
          </div>
          <div class="buttonBox">
            <button class="text-shadow no-buttonStyle" @click="$emit('next')">继续 <i class="fas fa-arrow-circle-right"></i></button>
          </div>
        </div>
      </div>
    </template>
    <template v-if="intro.introImage.isVideo">
      <div v-if="intro.firstImage" class="introImageBackGroundV" style="filter: blur(0);" :style="{'background-image':'url('+intro.firstImage.url.replace('/0/0/0/0/', '/800/0/0/0/')+')'}">
      </div>
      <div v-if="intro.isPro&& intro.logo" class="logoBox">
        <div class="text-center" :class="getAnimationClass('introAnimation3')">
          <img style="max-width:100%" :src="intro.logo.url">
        </div>
      </div>
      <div v-if="videoHide" class="introMainBox">
        <div class="text-shadow" :class="getAnimationClass('introAnimation2')" style="width: 500px; max-width: 100%;">
          <h3>{{intro.title}}</h3>
          <div v-if="intro.text">
            <div class="articleBox">
              <article class="introText" v-html="intro.text"></article>
            </div>
          </div>
          <div class="buttonBox">
            <button class="text-shadow no-buttonStyle" @click="videoHide=false">继续 <i class="fas fa-arrow-circle-right"></i></button>
          </div>
        </div>
      </div>
      <template v-if="!videoHide">
        <div class="videoBox">
          <div>
            <video ref="video" webkit-playsinline="true" x-webkit-airplay="true" playsinline="true" x5-playsinline="true" x5-video-player-type="h5-page" style="max-width:100%" :src="intro.introImage.url" :poster="intro.introImage.url.replace('/0/0/0/0/', '/800/0/0/0/')" @canplay="videoCanPlay" @play="pauseAudio" @abort="resumeAudio" @ended="resumeAudio();$emit('next')" controls />
          </div>
        </div>
        <div class="introMainBox">
          <div class="buttonBox" style="width: 500px; max-width: 100%;">
            <button class="text-shadow no-buttonStyle" @click="$emit('next')">继续 <i class="fas fa-arrow-circle-right"></i></button>
          </div>
        </div>
      </template>
    </template>
  </div>
  <div v-else>
    <div v-if="intro.firstImage" class="introImageBackGroundV" style="filter: blur(0);" :style="{'background-image':'url('+intro.firstImage.url.replace('/0/0/0/0/', '/800/0/0/0/')+')'}">
    </div>
    <img :src="intro.firstImage.url.replace('/0/0/0/0/', '/800/0/0/0/')" style="display:none" />
    <div v-if="intro.isPro&& intro.logo" class="logoBox">
      <div class="text-center" :class="getAnimationClass('introAnimation3')">
        <img style="max-width:100%" :src="intro.logo.url">
      </div>
    </div>
    <div class="introMainBox">
      <div class="text-shadow" :class="getAnimationClass('introAnimation2')" style="width: 500px; max-width: 100%;">
        <h3>{{intro.title}}</h3>
        <div v-if="intro.text">
          <div class="articleBox">
            <article class="introText" v-html="intro.text"></article>
          </div>
        </div>
        <div class="buttonBox">
          <button class="text-shadow no-buttonStyle" @click="$emit('next')">继续 <i class="fas fa-arrow-circle-right"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
    },
    data() {
      return {
        videoHide: true,
        Animations: { introAnimation1: false, },
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {
      }
    },
    watch: {
      intro(val) {
        this.videoHide = true
        //this.Animations = { introAnimation1: false, }
      }
    },
    computed: {
      intro() {
        return this.publicData.intro || {}
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
          next: {
            name: 'nextView',
            target: 'inViewData'
          },
        },
        funcs: {
        }
      })
      if (this.intro.introImage) {
        if (this.intro.introImage.isImage) {
          this.doAnimationGroup1()
        } else if (this.intro.introImage.isVideo) {
          this.doAnimationGroup1_2()
        }
      } else {
        this.doAnimationGroup1_2()
      }
    },
    destroyed() {
    },
    methods: {
      pauseAudio() {
        this.getFunc({ target: 'audio', name: 'pauseAll' })()
      },
      resumeAudio() {
        this.getFunc({ target: 'audio', name: 'resumeAll' })()
      },
      videoCanPlay(e) {
        //this.doAnimationGroup1_2()
        e.target.play()
      },
      doAnimationGroup1() {
        setTimeout(this.doAnimation, 1, 'introAnimation1')
        setTimeout(this.doAnimation, 2200, 'introAnimation2')
        setTimeout(this.doAnimation, 2500, 'introAnimation3')
        setTimeout(this.doAnimation, 4000, 'introAnimation4')
      },
      doAnimationGroup1_2() {
        setTimeout(this.doAnimation, 1, 'introAnimation1')
        setTimeout(this.doAnimation, 200, 'introAnimation2')
        setTimeout(this.doAnimation, 500, 'introAnimation3')
        setTimeout(this.doAnimation, 2000, 'introAnimation4')
      },
      doAnimationGroup2() {
        setTimeout(this.doAnimation, 1, 'introAnimation5')
        setTimeout(() => {
          this.intro = false
        }, 1100)
      },
      undoAnimationGroup2() {
        this.intro = 1
        setTimeout(this.undoAnimation, 1, 'introAnimation5')
      },
      getAnimationClass(name) {
        var r = {}
        r[name] = true
        r.active = this.Animations[name]
        return r
      },
      doAnimation(name) {
        this.Animations[name] = true
        this.$forceUpdate()
      },
      undoAnimation(name) {
        delete this.Animations[name]
        this.$forceUpdate()
      },
    },
  }
</script>
<style scoped>
  .no-buttonStyle {
    font-size: 16px;
    background-color: transparent;
    padding: 0 5px;
    border: none;
    height: unset;
    line-height: unset;
    color: orange;
  }

  .introImageBackGroundV {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    filter: blur(10px);
    transform: scale(1.1);
    z-index: -1;
  }

  .introImageBackGroundBox {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }

  .logoBox {
    position: absolute;
    top: 25px;
    left: 20px;
    max-width: 40vw;
    width: 200px;
  }

  .videoBox {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }

  .introMainBox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 55px;
    left: 45px;
    right: 45px;
  }

    .introMainBox .buttonBox {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      margin-top: 20px
    }

    .introMainBox .articleBox {
      overflow: auto;
      max-height: 18vh;
      min-width: 200px;
      min-height: 100px;
      padding: 3px;
    }

  .introAnimation1 {
    transition: all 2s;
    transform: scale(10);
  }

    .introAnimation1.active {
      transform: scale(1);
    }

  .introAnimation2 {
    transition: all 2s;
    transform: scale(0);
    opacity: 0;
  }

    .introAnimation2.active {
      transform: scale(1);
      opacity: 1;
    }

  .introAnimation3 {
    transition: all 2s;
    transform: translateX(500px) scale(0);
    opacity: 0;
  }

    .introAnimation3.active {
      transform: translateX(0) scale(1);
      opacity: 1;
    }

  .introAnimation4 {
    transition: all 2s;
    opacity: 0;
  }

    .introAnimation4.active {
      opacity: 1;
    }

  .introAnimation5 {
    transition: all 2s;
    opacity: 1;
  }

    .introAnimation5.active {
      opacity: 0;
    }
</style>
